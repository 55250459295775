






























































































































































import {Component, Prop} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import GroupAvatar from '@/components/GroupAvatar.vue';
import {copyTextToClipboard, unsubscribeSafe} from '@/utils/helpers';
import ContactInfoCard from '@/components/ContactInfoCard.vue';
import VcSwitch from '@/components/VCSwitch.vue';
import ContactInfoGeneral from '@/components/ContactInfoGeneral.vue';
import ContactInfoAssignment from '@/components/ContactInfoAssignment.vue';
import DepartmentInfoMembers from '@/components/DepartmentInfoMembers.vue';
import {Route} from 'vue-router';
import CustomerProfileGeneral from '@/components/business/customers/CustomerProfileGeneral.vue';
import DialogCreateEditCustomer from '@/components/business/customers/DialogCreateEditCustomer.vue';
import {customersStore} from '@/store/modules/customers/CustomersStore';
import DialogScheduleAppointment from '@/components/directory/customer/DialogScheduleAppointment.vue';
import PopupMenu from '@/components/PopupMenu.vue';
import Notifications from '@/components/mixins/Notifications';
import {mixins} from 'vue-class-component';
import DialogCreateNewActiveChat from '@/components/chats/DialogCreateNewActiveChat.vue';
import DialogConfirm from '@/components/DialogConfirm.vue';
import CustomerProfileNotes from '@/components/business/customers/CustomerProfileNotes.vue';
import {Unsubscribe} from 'firebase/firestore';
import {profileStore} from '@/store/modules/profile';
import {applicationStore} from '@/store/modules/application';
import ContentNotFound from '@/components/ContentNotFound.vue';

Component.registerHooks(['beforeRouteUpdate']);

@Component({
  name: 'customer-profile',
  components: {
    ContentNotFound,
    CustomerProfileNotes,
    DialogCreateEditCustomer,
    DialogCreateNewActiveChat,
    DialogConfirm,
    PopupMenu,
    DialogScheduleAppointment,
    CustomerProfileGeneral,
    DepartmentInfoMembers,
    ContactInfoAssignment,
    ContactInfoGeneral,
    VcSwitch,
    ContactInfoCard,
    GroupAvatar,
    AvatarWithStatus
  }
})
export default class CustomerProfile extends mixins(Notifications) {
  showDeleteContact: boolean = false;
  selectedTab: number = 0;

  showDialogEdit: boolean = false;
  showDialogScheduleAnAppointment: boolean = false
  showDialogReportUser: boolean = false
  showDialogConfirm: boolean = false
  titleDialog: string = ''
  textDialog: string = ''
  textColor: string = ''
  btnText: string = ''
  onConfirm?: any;

  showCreateChatDialog: boolean = false
  unsubscribeNotes: Unsubscribe | null = null
  reportMessage: string = ''
  isValid: boolean = false;

  @Prop() customerId;
  @Prop({default: 'all'}) type!: string;
  @Prop({default: false}) readonly inChatInfo!: boolean
  @Prop({default: 'Chat'}) backText!: string

  @Getter selectedCustomer;

  get userId() {
    return profileStore.t2bUser.id
  }

  get admin() {
    return profileStore.isAdmin
  }

  get isBlocked() {
    return this.selectedCustomer?.blocked?.includes(this.userId)
  }

  get isManual() {
    return !this.selectedCustomer?.isManual;
  }

  get isVip() {
    return !!this.selectedCustomer?.vip;
  }

  get isOptIn() {
    return (!!this.permissions?.contact || !!this.permissions?.promote);
  }

  get isShared() {
    return !!this.selectedCustomer?.shared;
  }

  get inPersonal() {
    return this.selectedCustomer?.personal?.includes(this.userId);
  }

  get allowedContact(): boolean {
    return this.permissions?.contact;
  }

  get showMenu() {
    switch (this.type) {
      case 'personal':
        return true;
      case 'all':
      case 'vip':
      case 'opt-in':
      case 'shared':
      default:
        return this.admin;
    }
  }

  // todo: refactor!!!
  get menu() {
    switch (this.type) {
      case 'all':
      case 'opt-in':
        return [
          {
            title: 'Edit',
            index: 7,
            skip: this.isManual
          },
          {
            title: 'Add to VIP',
            index: 0,
            skip: this.isVip
          },
          {
            title: 'Add to Shared',
            index: 1,
            skip: this.isShared
          },
          {
            title: 'Add to My contacts',
            index: 4,
            skip: this.inPersonal
          },
          {
            title: 'Report',
            index: 5
          },          
          {
             title: 'Remove',
             index: 2,
             alert: true,
             skip: this.isManual
          }
        ];
      case 'vip':
        return [
          {
            title: 'Remove from VIP',
            index: 0
          },
          {
            title: 'Add to Shared',
            index: 1,
            skip: this.isShared
          },
          {
            title: 'Add to My contacts',
            index: 4,
            skip: this.inPersonal
          },
          {
            title: 'Report',
            index: 5
          },
          // TODO: temporary hidden
          // {
          //   title: 'Delete',
          //   index: 2,
          //   alert: true
          // }
        ]
      case 'personal':
        return [
          {
            title: 'Report',
            index: 5
          },
          {
            title: 'Remove from My contacts',
            index: 3
          }
        ];
      case 'shared':
        return [
          {
            title: 'Add to My contacts',
            index: 4,
            skip: this.inPersonal
          },
          {
            title: 'Report',
            index: 5
          },
          {
            title: 'Remove from Shared',
            index: 6,
            skip: !this.isAdmin
          }
        ];
      default:
        return [];
    }
  }

  get isAdmin() {
    return profileStore.isAdmin
  }

  get title(): string {
    return this.selectedCustomer?.fullName;
  }

  get subtitle(): string {
    return `${this.selectedCustomer?.email}`;
  }

  get photoUrl(): string {
    return this.selectedCustomer?.photoUrl?.normal;
  }

  get online(): boolean {
    return !!this.selectedCustomer?.status?.online;
  }

  get permissions() {
    return this.selectedCustomer?.permissions;
  }

  @Action shareCustomerContactLink;
  @Action loadCustomerById;

  // todo: refactor!!!
  onMenuItemClicked(menuItem) {
    switch (menuItem.index) {
      case 0: {
        if (this.selectedCustomer.vip) {
          this.showInfo('Removed from VIP');
        } else {
          this.showInfo('Added to VIP');
        }
        customersStore.toggleVipStatus(this.selectedCustomer);
        break;
      }
      case 1: {
        if (this.selectedCustomer.shared) {
          this.showInfo('Removed from Shared');
        } else {
          this.showInfo('Added to Shared');
        }
        customersStore.toggleSharedStatus(this.selectedCustomer);
        break;
      }
      case 2: {        
        this.titleDialog = `Do you want to remove contact '${this.title}'?`;
        this.textDialog = 'Warning: This action can’t be undone.';
        this.textColor = 'text-secondary'
        this.btnText = 'Yes, remove'
        this.onConfirm = async () => {
          try {     
            let customerName =  this.title;
            await applicationStore.deleteCustomer(this.selectedCustomer.id);
            this.showInfo(`${customerName} was deleted`);     
          } catch (e: any) {
            this.showIssue(e.message);
          }
        }
        this.showDialogConfirm = true;
        break;
      }
      case 3: {
        customersStore.removeFromPersonalContacts(this.selectedCustomer.id);
        this.showInfo('Removed from My contacts');
        break;
      }
      case 4: {
        customersStore.addToPersonalContacts(this.selectedCustomer.id)
        this.showInfo('Added to My contacts')
        break;
      }
      case 5: {
        this.titleDialog = 'Report'
        this.textDialog = ''
        this.btnText = 'Send'
        this.onConfirm = async () => {
          try {
            await customersStore.reportCustomer({customer: this.selectedCustomer, message: this.reportMessage})
            this.reportMessage = ''
            this.showInfo('User has been reported')
          } catch (err) {
            this.showIssue('Failed to report user')
          }
        }
        this.showDialogConfirm = true;
        break;
      }
      case 6: {
        customersStore.toggleSharedStatus(this.selectedCustomer);
        this.showInfo('Removed from Shared');
        break;
      }
      case 7: {
        this.showDialogEdit = true;
        break;
      }
      default:
    }
  }

  async shareContact() {
    if (!this.selectedCustomer) {
      return;
    }
    try {
      const shortLink = await this.shareCustomerContactLink(this.selectedCustomer.id);
      await copyTextToClipboard(shortLink);
      this.showInfo('Copied to clipboard');
    } catch (err: any) {
      console.error(`share contact. error: ${err}`);
      this.$emit('on-error', err.message);
    }
  }

  onScheduleAppoint() {
    this.showDialogScheduleAnAppointment = true
  }

  async onSaveReport() {
    try {
      await customersStore.reportCustomer({customer: this.selectedCustomer, message: this.reportMessage})
      this.reportMessage = ''
      this.showInfo('User has been reported')
    } catch (err) {
      this.showIssue('Failed to report user')
    }
  }

  onUnblockUser() {
    profileStore.unblockUser(this.customerId)
  }

  async created() {
    this.isValid = true;
    let href = window.location.href.toString();  

    if(!!this.selectedCustomer && href.includes('vip') && !this.isVip){
      this.isValid = false;
    }
    if(!!this.selectedCustomer && href.includes('opt-in') && !this.isOptIn){
      this.isValid = false;
    }   
    
    this.loadCustomerById(this.customerId);
    this.unsubscribeNotes = await customersStore.loadNotes(this.customerId);
  }

  onChangesProfile() {
    this.showInfo('Profile was changed');
  }
  
  beforeDestroy() {
    unsubscribeSafe(this.unsubscribeNotes)
  }

  async beforeRouteUpdate(to: Route, from: Route, next) {
    this.isValid = true;
    this.loadCustomerById(to.params.customerId);
    unsubscribeSafe(this.unsubscribeNotes)
    this.unsubscribeNotes = await customersStore.loadNotes(to.params.customerId);
    next();
  }
}
